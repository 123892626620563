.section{
    display: flex;
    align-items: center;
    width: 100%;
}
.col-md-4{
    display: flex;
    text-align: justify;
    flex-direction: column;
    margin: 2rem 1rem;
    width: 30%;
}
.titulo_section{
    text-align: center;
    color: #173647;

}
.acerca_nosotros{
    margin: 1rem 7rem;
}