* {
    padding: 0;
    margin: 0;
}
.navigation{
    width: 100%;
    height: 70px;
}
.navbar-nav{
    margin: 0%;
    padding: 0%;
}
.bg-custom{
    background-color: #173647;
    z-index: 60%;
    padding: 0%;
    height:100% ;
}
.nav-link:hover {
    text-decoration-line: overline;
}
.navbar-nav .container{
    width: 100%;
}