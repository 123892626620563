.hrCard{
    height: 1px;
    width: 100%;
    color: black;
    
}
.container_precio{
    font-weight: 600;
    margin: 2% 2%;
}
.texto_precio{
    display: flex;
    font-size: 2vh;
    margin: 5% 2%;
}
.precio{
    display: flex;
    align-items: flex-end;
    font-size: xx-large;
}