
.btn-enviar{
    display: flex;
    background-color: #173647 ;
    align-items: center;
    justify-content: center;
    width: 10em;
    height: 2em;
    color: white;
}
.container_info{
    color: black;
    background-color: rgb(239, 241, 243);
}