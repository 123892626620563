.informacion{
    margin: 0% 15% 4%;
    padding: 0%;
    background-color: rgb(239, 241, 243);
}
.Titulo {
    font-size: 2em;
    position: relative;
    margin: 1rem 0em;
    padding: 1em 1em .25em 0em;
    text-align: center;
    text-transform: uppercase;
    color: #173647;
}
.Titulo:after {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 240px;
    height: 4px;
    margin-left: -128px;
    content: '';
    background-color: #173647;
}

.Texto_info{
    text-align: justify;
}
.text-box {
	position: relative;
	display: flex;
    justify-content: flex-start;
    margin: 0%;
}
.texto_centrado{
    display: flex;
    color: #173647;
    font-size: medium;
}
