
.d-block{
    object-fit: cover;
    height: 35rem;
    margin: 0.1% 0% 0% 0%;
    background-size: cover;
    background-position: center;
    width: 99%;
}
.slider-interno{
    width: 100%;
    height: 10%;
}