
.Quienes-somos{
    font-size: 2em;
    position: relative;
    margin: 1rem 0em;
    padding: 1em 1em .25em 1em;
    text-align: center;
    text-transform: uppercase;
    color: #173647;
}
.Quienes-somos:after {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 240px;
    height: 4px;
    margin-left: -120px;
    content: '';
    background-color: #173647;
}

